import React from 'react';
import { Row } from 'antd';
import Loader from '../Loader';

function LoadingPage({ isHeight }: any): React.ReactElement {
  return (
    <Row align="middle" justify="center" className={`${isHeight ? 'height-100' : 'full-page'}`}>
      <Loader isLoading />
    </Row>
  );
}

export default LoadingPage;
