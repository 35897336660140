export interface Building {
  name: string;
  id: string;
}

export interface Buildings {
  data: Building[];
  isLoading: boolean;
  selected: string | null;
}

const initialState = {
  data: [],
  isLoading: false,
  selected: null,
};

export default initialState;
