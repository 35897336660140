import LOCALES from 'utils/locales';
import {
  sideMenu,
  button,
  labels as label,
  placeHolders as placeHolder,
  title,
  errors as error,
  messages as message,
  text,
} from '../label';

const sideMenuLabels = {
  [sideMenu.analytics]: 'Analytics',
  [sideMenu.workers]: 'Workers',
  [sideMenu.configurations.workerRoles]: 'Worker Roles',
  [sideMenu.configurations.subContractors]: 'Subcontractors',
  [sideMenu.mapsZones]: 'Maps & Zones',
  [sideMenu.crewsWorkshifts]: 'Crews & Workshifts',
  [sideMenu.crews]: 'Crews',
  [sideMenu.workshifts]: 'WorkShifts',
  [sideMenu.installationMode]: 'Installation Mode',
  [sideMenu.maintance.title]: 'Maintenance Kit',
  [sideMenu.maintance.mapView]: 'Map View',
  [sideMenu.maintance.mapViewTitle]: 'Maintenance Toolkit',
  [sideMenu.userManagement]: 'User Management',
  [sideMenu.configurations.title]: 'Configurations',
};

const buttonLabels = {
  [button.create]: 'Create',
  [button.cancel]: 'Cancel',
  [button.update]: 'Update',
  [button.logout]: 'Logout',
  [button.proceed]: 'Proceed',
  [button.upload]: 'Upload',
  [button.uploadExcel]: 'Upload Excel',
  [button.reUploadExcel]: 'Re-Upload Excel',
  [button.addNewClient]: 'Add New Client',
  [button.yes]: 'Yes',
  [button.no]: 'No',
  [button.confirm]: 'Confirm',
  [button.add]: 'Add',
};

const labels = {
  [label.name]: 'Name',
  [label.role]: 'Role',
  [label.mobileNumber]: 'Mobile Number',
  [label.roleName]: 'Role Name',
  [label.subcontractorName]: 'Subcontractor Name',
  [label.helmet]: 'Helmet',
  [label.helmetColor]: 'Helmet Color',
  [label.workerTagId]: 'Worker Tag ID',
  [label.contractor]: 'Contractor',
  [label.selectLanguage]: 'Select Language',
  [label.clientName]: 'Client Name',
  [label.address]: 'Address',
  [label.crew]: 'Crew',
  [label.workshift]: 'Workshift',
  [label.crewDetails]: 'Crew Details',
  [label.workshiftDetails]: 'Workshift Details',
  [label.dateFrom]: 'Date From',
};

const placeHolders = {
  [placeHolder.name]: 'Enter Name',
  [placeHolder.mobile]: 'Enter Mobile Number',
  [placeHolder.roleName]: 'Enter Role Name',
  [placeHolder.workerName]: 'Worker Name',
  [placeHolder.selectRole]: 'Select Role',
  [placeHolder.selectHelmet]: 'Select Helmet',
  [placeHolder.selectHelmetId]: 'Select Helmet ID',
  [placeHolder.selectContractor]: 'Select Contractor',
  [placeHolder.selectSubContractor]: 'Select Subcontractor',
  [placeHolder.selectSite]: 'Select Site',
  [placeHolder.tagId]: 'Tag ID',
  [placeHolder.selectClient]: 'Select Client',
  [placeHolder.selectBuilding]: 'Select Building',
  [placeHolder.workshiftName]: 'Enter Workshift Name',
  [placeHolder.category]: 'Select Category',
  [placeHolder.crewName]: 'Enter Crew Name',
  [placeHolder.crewId]: 'Enter Crew ID',
  [placeHolder.crew]: 'Select Crew',
  [placeHolder.workshift]: 'Select Workshift',
  [placeHolder.searchWorkshiftName]: 'Workshift Name',
  [placeHolder.searchWorkers]: 'Search workers',
  [placeHolder.filterByContractor]: 'Filter by Subcontractor',
  [placeHolder.filterByRole]: 'Filter by Role',
  [placeHolder.filterByCrew]: 'Filter by Crew',
  [placeHolder.filterByStatus]: 'Filter by Status',
  [placeHolder.searchCrews]: 'Crew Name/ID',
  [placeHolder.searchZones]: 'Search Zones',
  [placeHolder.searchNodes]: 'Search Nodes',
};
const titles = {
  [title.worker]: 'Worker',
  [title.addWorker]: 'Add Worker',
  [title.editWorker]: 'Edit Worker',
  [title.workerRole]: 'Worker Role',
  [title.addWorkerRole]: 'Add Worker Role',
  [title.editWorkerRole]: 'Edit Worker Role',
  [title.subcontractor]: 'Subcontractor',
  [title.addSubcontractor]: 'Add Subcontractor',
  [title.createClient]: 'Create Client',
  [title.workerName]: 'Worker Name',
  [title.role]: 'Role',
  [title.tagId]: 'Tag ID',
  [title.helmetID]: 'Helmet ID',
  [title.building]: 'Building',
  [title.floor]: 'Floor',
  [title.zone]: 'Zone',
  [title.lastSeen]: 'Last Seen',
  [title.actions]: 'Actions',
  [title.crew]: 'Crew',
  [title.workShift]: 'Workshift',
  [title.joinedDate]: 'Joined Date',
  [title.location]: 'Location',
  [title.site]: 'Site',
  [title.quickStats]: 'Quick Stats',
  [title.totalWorkers]: 'Total Workers',
  [title.deployedHelmets]: 'Deployed Helmets',
  [title.assignedHelments]: 'Assigned Helmets',
  [title.onlineHelmets]: 'Online Assigned Helmets',
  [title.activeHelmets]: 'Active Helmets',
  [title.inactiveHelmets]: 'Inactive Helmets',
  [title.withoutHelmets]: 'without Helmets',
  [title.allDeployedHelmets]: 'All deployed helmets at site',
  [title.assignedHelmetsOnly]: 'Assigned helmets only',
  [title.onlineHelmetsInfo]: 'Total online helmets',
  [title.activeHelmetsInfo]: 'Active assigned helmets shown',
  [title.inactiveHelmetsInfo]: 'Inactive assigned helmets shown',
  [title.trackWorkers]: 'Track Workers',
  [title.active]: 'Active',
  [title.inactive]: 'Inactive',
  [title.return]: 'Return',
  [title.zoom]: 'Zoom',
  [title.zoneName]: 'Zone Name',
  [title.addWorkshift]: 'Add Workshift',
  [title.workshiftName]: 'Workshift Name',
  [title.category]: 'Category',
  [title.nonWorkingDays]: 'Non Working Days',
  [title.duration]: 'Duration',
  [title.startTime]: 'Start Time',
  [title.endTime]: 'End Time',
  [title.breakStartTime]: 'Break Start Time',
  [title.breakEndTime]: 'Break End Time',
  [title.breakStartTimeWithNumber]: 'Break {name} Start Time',
  [title.breakEndTimeWithNumber]: 'Break {name} End Time',
  [title.addCrew]: 'Add Crew',
  [title.crewId]: 'Crew ID',
  [title.crewName]: 'Crew Name',
  [title.assignHelmet]: 'Assign Helmet',
  [title.unAssignHelmet]: 'Unassign Helmet',
  [title.breaks]: 'Breaks',
  [title.noWorkers]: 'There are no workers on the crew.',
  [title.locatedOn]: 'Located on',
  [title.workshiftDetails]: 'Workshift Details',
  [title.bulkUploadTitle]: 'Build Upload - Create Workers',
  [title.downloadTemplate]: 'Download Worker Excel Template',
  [title.clearTrack]: 'Clear Tracking',
  [title.createdAt]: 'Created Date',
  [title.numberOfWorkers]: 'Number of Workers',
};

const texts = {
  [text.roles]: 'roles',
  [text.contractors]: 'contractors',
  [text.helmets]: 'helmets',
  [text.sites]: 'sites',
  [text.clients]: 'clients',
  [text.workers]: 'workers',
  [text.nA]: '-NA-',
  [text.infoMessage1Hour]: 'The data is from last 1 hour',
  [text.floors]: 'floors',
  [text.buildings]: 'buildings',
  [text.crews]: 'crews',
  [text.workshifts]: 'workshifts',
  [text.analytics]: 'analytics',
  [text.subcontractors]: 'subcontractors',
};

const errors = {
  [error.required]: '{name} is required',
  [error.min]: '{name} accepts a minimum of {val} characters',
  [error.max]: '{name} accepts a maximum of {val} characters',
  [error.minDigits]: '{name} accepts a minimum of {val} digits',
  [error.maxDigits]: '{name} accepts a maximum of {val} digits',
  [error.onlyDigits]: '{name} accepts only digits',
  [error.image]: `PNG, JPEG, JPG formats are allowed, image can't exceed 3MB`,
  [error.exceedDuration]: `Breaks duration can't exceed workshift duration`,
  [error.sequential]: `Breaks must be sequential`,
  [error.conflicting]: 'Breaks are conflicting with each other',
  [error.fallOutSide]: 'Break start or end time cannot fall outside workshift period',
  [error.uploadSizeExceed]: `File size can't exceed 10 MB`,
  [error.uploadType]: `File type isn't supported.`,
};

const messages = {
  [message.created]: '{name} is created successfully',
  [message.confirm]:
    'You are about to cancel all your updates. All changes will be unsaved, proceed?',
  [message.dataNA]: 'No {name} available',
  [message.unknowError]: 'Something went wrong, please contact support!',
  [message.tryAgain]: 'Something went wrong please try again',
  [message.noInternet]: 'No internet connection, please check your connection and try again',
  [message.internetBack]: `Internet is back!`,
  [message.deleteConfirm]: `Are you sure to delete {name} information, this action can't be undone?`,
  [message.deleteSuccess]: '{name} is deleted successfully',
  [message.unableToload]: 'Unable to load the blueprint',
  [message.pleaseTryAgain]: 'Please try again',
  [message.unAssignConfim]: 'Are you sure to unassign helmet?',
  [message.assigned]: 'The helmet is assigned successfully',
  [message.uploadSuccess]: 'Upload Success',
};

export default {
  [LOCALES.ENGLISH]: {
    ...sideMenuLabels,
    ...buttonLabels,
    ...labels,
    ...placeHolders,
    ...titles,
    ...texts,
    ...errors,
    ...messages,
  },
};
