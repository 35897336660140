import initialState, { User } from 'store/state/user';
import { LOGIN, UserActions, AUTHENTICATE } from 'store/actions/user';
import { USER_LANG_SET, USER_SET_LOADING, USER__LAST_SELECTION } from 'store/actions/actionTypes';

const userReducer = (state = initialState, action: UserActions): User => {
  switch (action.type) {
    case LOGIN:
    case AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: true,
        role: action.payload.user_role,
        ...action.payload,
      };
    case USER_LANG_SET:
      return {
        ...state,
        locale: action.payload,
      };
    case USER_SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case USER__LAST_SELECTION:
      return {
        ...state,
        last_client_id: null,
        last_site_id: null,
      };
    default:
      return state;
  }
};
export default userReducer;
