export const sideMenu = {
  analytics: 'sideMenu.analytics',
  workers: 'sideMenu.workers',
  mapsZones: 'sideMenu.mapsZones',
  crewsWorkshifts: 'sideMenu.crewsWorkshifts',
  crews: 'sideMenu.crews',
  workshifts: 'sideMenu.workshifts',
  installationMode: 'sideMenu.installationMode',
  maintance: {
    title: 'sidemenu.maintance.title',
    mapView: 'sidemenu.maintance.mapView',
    mapViewTitle: 'sidemenu.maintance.mapViewTitle',
  },
  userManagement: 'sideMenu.userManagement',
  configurations: {
    title: 'sideMenu.configurations.title',
    workerRoles: 'sideMenu.configurations.workerRoles',
    subContractors: 'sideMenu.configurations.subContractors',
  },
};

export const button = {
  create: 'button.create',
  cancel: 'button.cancel',
  update: 'button.update',
  logout: 'button.logout',
  proceed: 'button.proceed',
  upload: 'button.upload',
  uploadExcel: 'button.uploadExcel',
  reUploadExcel: 'button.reUploadExcel',
  addNewClient: 'button.addNewClient',
  yes: 'button.yes',
  no: 'button.no',
  confirm: 'button.confirm',
  add: 'button.add',
};

export const labels = {
  name: 'label.name',
  role: 'label.role',
  mobileNumber: 'label.mobileNumber',
  roleName: 'label.roleName',
  subcontractorName: 'label.subcontractorName',
  helmet: 'label.helmet',
  helmetColor: 'label.helmetColor',
  workerTagId: 'label.workerTagId',
  contractor: 'label.contractor',
  selectLanguage: 'label.selectLanguage',
  clientName: 'label.clientName',
  address: 'label.address',
  crew: 'label.crew',
  crewDetails: 'label.crewDetails',
  workshiftDetails: 'label.workshiftDetails',
  workshift: 'label.workshift',
  dateFrom: 'label.dateFrom',
};

export const placeHolders = {
  name: 'placeholder.name',
  mobile: 'placeholder.mobile',
  roleName: 'placeholder.roleName',
  workerName: 'placeholder.workerName',
  selectRole: 'placeholder.selectRole',
  selectHelmet: 'placeholder.selectHelmet',
  selectHelmetId: 'placeholder.selectHelmetId',
  selectContractor: 'placeholder.selectContractor',
  selectSubContractor: 'placeholder.selectSubContractor',
  selectSite: 'placeholder.selectSite',
  tagId: 'placeholder.tagId',
  selectClient: 'placeholder.selectClient',
  selectBuilding: 'placeholder.selectBuilding',
  workshiftName: 'placeholder.workshiftName',
  category: 'placeholder.category',
  crewName: 'placeholder.crewName',
  crewId: 'placeholder.crewId',
  crew: 'placeholder.crew',
  workshift: 'placeholder.workshift',
  searchWorkshiftName: 'placeholder.searchWorkshiftName',
  searchWorkers: 'placeholder.searchWorkers',
  filterByContractor: 'placeholder.filterByContractor',
  filterByRole: 'placeholder.filterByRole',
  filterByCrew: 'placeholder.filterByCrew',
  filterByStatus: 'placeholder.filterByStatus',
  searchCrews: 'placeholder.searchCrews',
  searchZones: 'placeHolder.searchZones',
  searchNodes: 'placeHolder.searchNodes',
};

export const title = {
  worker: 'title.worker',
  addWorker: 'title.addWorker',
  editWorker: 'title.editWorker',
  workerRole: 'title.workerRole',
  addWorkerRole: 'title.addWorkerRole',
  editWorkerRole: 'title.editWorkerRole',
  // SubContracor
  subcontractor: 'title.subcontractor',
  addSubcontractor: 'title.addSubcontractor',
  createClient: 'title.createClient',
  workerName: 'title.workerName',
  role: 'title.role',
  tagId: 'title.tagId',
  helmetID: 'title.helmetID',
  building: 'title.building',
  floor: 'title.floor',
  zone: 'title.zone',
  lastSeen: 'title.lastSeen',
  actions: 'title.actions',
  crew: 'title.crew',
  workShift: 'title.workShift',
  joinedDate: 'title.joinedDate',
  location: 'title.location',
  site: 'title.site',
  quickStats: 'title.quickStats',
  totalWorkers: 'title.totalWorkers',
  deployedHelmets: 'title.deployedHelmets',
  assignedHelments: 'title.assignedHelments',
  onlineHelmets: 'title.onlineHelmets',
  activeHelmets: 'title.activeHelmets',
  inactiveHelmets: 'title.inactiveHelmets',
  withoutHelmets: 'title.withoutHelmets',
  allDeployedHelmets: 'title.allDeployedHelmets',
  assignedHelmetsOnly: 'title.assignedHelmetsOnly',
  onlineHelmetsInfo: 'title.onlineHelmetsInfo',
  activeHelmetsInfo: 'title.activeHelmetsInfo',
  inactiveHelmetsInfo: 'title.inactiveHelmetsInfo',
  trackWorkers: 'title.trackWorkers',
  active: 'title.active',
  inactive: 'title.inactive',
  return: 'title.return',
  zoom: 'title.zoom',
  zoneName: 'title.zoneName',
  addWorkshift: 'title.addWorkshift',
  workshiftName: 'title.workshiftName',
  category: 'title.category',
  nonWorkingDays: 'title.nonWorkingDays',
  duration: 'title.duration',
  startTime: 'title.startTime',
  endTime: 'title.endTime',
  breakStartTime: 'title.breakStartTime',
  breakStartTimeWithNumber: 'title.breakStartTimeWithNumber',
  breakEndTimeWithNumber: 'title.breakEndTimeWithNumber',
  breakEndTime: 'title.breakEndTime',
  addCrew: 'title.addCrew',
  crewId: 'title.crewId',
  crewName: 'title.crewName',
  assignHelmet: 'title.assignHelmet',
  unAssignHelmet: 'title.unAssignHelmet',
  breaks: 'title.breaks',
  noWorkers: 'title.noWorkers',
  locatedOn: 'title.locatedOn',
  workshiftDetails: 'title.workshiftDetails',
  bulkUploadTitle: 'title.bulkUploadTitle',
  downloadTemplate: 'title.downloadTemplate',
  clearTrack: 'title.clearTrack',
  createdAt: 'title.createdAt',
  numberOfWorkers: 'title.numberOfWorkers',
};

export const text = {
  roles: 'text.roles',
  contractors: 'text.contractors',
  helmets: 'text.helmets',
  sites: 'text.sites',
  clients: 'text.clients',
  workers: 'text.workers',
  nA: 'text.nA',
  infoMessage1Hour: 'text.infoMessage1Hour',
  floors: 'text.floors',
  buildings: 'text.buildings',
  crews: 'text.crews',
  workshifts: 'text.workshifts',
  analytics: 'text.analytics',
  subcontractors: 'text.subcontractors',
};

export const errors = {
  required: 'error.required',
  min: 'error.min',
  max: 'error.max',
  minDigits: 'error.minDigits',
  maxDigits: 'error.maxDigits',
  onlyDigits: 'error.onlyDigits',
  image: 'error.image',
  exceedDuration: 'error.exceedDuration',
  sequential: 'error.sequential',
  conflicting: 'error.conflicting',
  fallOutSide: 'error.fallOutSide',
  uploadSizeExceed: 'error.uploadSizeExceed',
  uploadType: 'error.uploadType',
};

export const messages = {
  created: 'message.created',
  confirm: 'message.confirm',
  dataNA: 'message.dataNA',
  unknowError: 'message.unknowError',
  tryAgain: 'message.tryAgain',
  noInternet: 'message.noInternet',
  internetBack: 'message.internetBack',
  deleteConfirm: 'message.deleteConfirm',
  deleteSuccess: 'message.deleteSuccess',
  unableToload: 'message.unableToload',
  pleaseTryAgain: 'message.pleaseTryAgain',
  unAssignConfim: 'message.unAssignConfirm',
  assigned: 'message.assigned',
  uploadSuccess: 'message.uploadSuccess',
};
