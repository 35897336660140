import messages from 'i18n/messages';
import { messages as message, placeHolders } from 'i18n/label';
import { getLocaleFromStorage } from './browser-storage';

export const AUTH_KEY = 'auth';
export const USER_KEY = 'user';
export const UNKNOWN_ERROR = 'Something went wrong please contact support';
// eslint-disable-next-line operator-linebreak
export const NO_INTERNET_MESSAGE =
  'No internet connection, please check your connection and try again';
export const unknowError = (): string => {
  const locale = getLocaleFromStorage();
  return messages[locale][message.unknowError];
};

export const noInternetMessage = (): string => {
  const locale = getLocaleFromStorage();
  return messages[locale][message.noInternet];
};

export const internetBackMessage = (): string => {
  const locale = getLocaleFromStorage();
  return messages[locale][message.internetBack];
};

export const translateSelectContractor = (): string => {
  const locale = getLocaleFromStorage();
  return messages[locale][placeHolders.selectContractor];
};

// eslint-disable-next-line operator-linebreak
export const IMAGE_PLACEHOLDER =
  'https://wakecap-public.s3.us-east-2.amazonaws.com/images/default-profile-picture.jpg';

export const ACTIVE_STATUS = 'active';
export const INACTIVE_STATUS = 'inactive';
export const NOT_VERIFED_STATUS = 'invited';

export const USER_CANCELLED = 'USER_CANCELLED';
