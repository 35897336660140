import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { LOGIN } from 'utils/routes';
import { useSelector } from 'react-redux';

interface PrivateRouteProps extends RouteProps {
  roleCheck?: string[];
}

function PrivateRoute(props: PrivateRouteProps): React.ReactElement {
  // @ts-ignore
  const user = useSelector((state) => state.user);

  if (!user.isAuthenticated) {
    return <Redirect to={{ pathname: LOGIN }} />;
  }

  if (props.roleCheck) {
    if (!props.roleCheck.includes(user.role as string)) {
      return <Redirect to={{ pathname: LOGIN }} />;
    }
  }

  return <Route {...props} />;
}

export default PrivateRoute;
