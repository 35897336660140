import React from 'react';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export interface LoaderProps {
  isLoading: boolean;
  className?: string;
}

function Loader(props: LoaderProps): React.ReactElement {
  const { isLoading, className = '' } = props;
  function LoaderIcon(): React.ReactElement {
    return <Loading3QuartersOutlined spin style={{ fontSize: 48 }} />;
  }

  return <Spin indicator={<LoaderIcon />} spinning={isLoading} className={className} />;
}

export default Loader;
