import { FieldError } from 'store/types';
import { FormInstance } from 'antd/lib/form';

/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */

/**
 * Converts a given value to an Integer. It performs conversion using the bitwise negate operator
 *
 * @param {*} value
 * @returns {number}
 */
export function convertToInteger(value: string | number): number {
  // eslint-disable-next-line
  return ~~value;
}

/**
 * Renders a templatize string
 *
 * @function renderTemplateString
 * @param {String} input String to be rendered
 * @param {Object.<String, *>} data The data to be replace
 * @param {String} [delimiter=':']
 * @returns {String}
 */
export function renderTemplateString(input: string, data: object, delimiter = ':'): string {
  if (!Object.keys(data).length || Array.isArray(input) || !delimiter) {
    return input;
  }
  input += '';
  const params = Object.keys(data);
  // Check if any of the provided params are present in the string
  const param = params.find((value) => input.includes(delimiter + value));
  if (param) {
    const start = input.lastIndexOf(delimiter + param);
    // eslint-disable-next-line no-bitwise
    if (~start) {
      const end = start + param.length;
      const value = data[param];
      input = renderTemplateString(input.substring(0, start), data);
      value + renderTemplateString(input.substring(end + 1), data);
    }
  }
  return input;
}

/**
 *
 * @param fieldErrors Errors object to check if there is errors in the form
 * @returns {Boolean}
 */
export function hasErrors(fieldErrors: FieldError[]): boolean {
  return !!fieldErrors.filter(({ errors }) => errors.length).length;
}

/**
 *
 * @param fieldErrors Errors object to check if there is errors in the form
 * @returns {Boolean}
 */
export function hasErrorsWithout(fieldErrors: FieldError[], names: string[]): boolean {
  return !!fieldErrors.filter(({ errors, name }) => {
    if (names.includes(name[0] as string)) {
      return false;
    }
    return errors.length;
  }).length;
}

/**
 *
 * @param values Values submitted in the form
 * @returns {Boolean}
 */
export function hasValues(values: Record<string, string[] | undefined>): boolean {
  return !!Object.values(values).find((value) => value !== undefined);
}

/**
 *
 * @param {FormInstance} form
 */
export function isFormValid(form: FormInstance): boolean {
  return hasValues(form.getFieldsValue()) && !hasErrors(form.getFieldsError());
}

/**
 *
 * @param {FormInstance} form form isntance
 * @param {Array} keys array of required value keys
 */
export function isFormHasValidValues(
  form: FormInstance,
  keys: (string | string[])[],
  nestedValues?: boolean,
): boolean {
  const errorsArray = form.getFieldsError(keys);
  const values = form.getFieldsValue(keys);
  let isValues = hasValues(values);
  if (nestedValues) {
    isValues = Object.values(values).every((value: any) => {
      if (typeof value === 'object') {
        return Object.values(value).every((nestedValue: any) => {
          if (typeof nestedValue === 'object') {
            return Object.values(nestedValue).every((doubleNested) => doubleNested !== undefined);
          }
          return nestedValue !== undefined;
        });
      }
      return value !== undefined;
    });
  } else {
    isValues = Object.values(values).every((value: any) => value !== undefined);
  }
  const isErrors = !!errorsArray.filter(({ errors }: any) => errors.length).length;
  return isValues && !isErrors;
}

/**
 *
 */
export function pickFirstArrayItem<T>(array: T[]): T {
  return array[0];
}

/**
 * @description Showing a browser warning
 * @param {String} message to be shown in the warning
 * @param {Funciton} callback to be invoked on warning confirm
 */
export function showWarning(message: string, callback: () => void): void {
  // eslint-disable-next-line no-alert
  const confirm = window.confirm(message);
  if (confirm && callback) {
    return callback();
  }
}

export function getFileSizeInMB(file: File | Blob): number {
  return file.size / 1024 / 1024;
}

export function convertHexToRGBA(hex: string, opacity: number): string {
  hex = hex.replace('#', '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b}, ${opacity})`;
}

// export const truncateText = (input: string, coordinate: any) => {
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');

//   let text = ctx?.measureText(input); // TextMetrics object
//   let widthText: number | undefined = text?.width;
//   let result;

//   if (widthText) {
//     let subtraction = widthText / input.length;
//     subtraction = Math.floor(coordinate[0] / subtraction);
//     subtraction = Math.floor(coordinate[0] - subtraction);
//     let comparison = widthText <= coordinate[0];
//     result = comparison ? input : `${input.slice(0, subtraction)}...`;
//   }
//   return result;
// };

export const truncateTexts = (input: string, coordinate: any): string => {
  const text = document.createElement('span');
  document.body.appendChild(text);

  text.style.font = 'times new roman';
  text.style.fontSize = `${16}px`;
  text.style.height = 'auto';
  text.style.width = 'auto';
  text.style.position = 'absolute';
  text.style.whiteSpace = 'no-wrap';
  text.innerHTML = input;
  const width = text.clientWidth;
  // let height = text.clientHeight + 1;

  // console.log('height', height);

  const subtraction = width / input.length;
  const comparison = width <= coordinate[0];
  const addition = Math.ceil(coordinate[0] / subtraction);
  text.remove();
  const result = comparison ? input : `${input.slice(0, addition)}...`;
  return result;
};
