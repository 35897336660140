export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const REGISTER = '/register/:signature';
export const APP = '/';
export const ANALYTICS = '/';
export const WORKERS = '/workers';
export const CREWS = '/crews';
export const WORKSHIFTS = '/workshifts';
export const INSTALLATION = '/installation';
export const USER_MANAGEMENT = '/users';
export const MAINTENANCE = '/maintenance';
export const MAINTENANCE_MAP = `${MAINTENANCE}/map`;
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password/:token';
export const CONFIGURATION = '/configuration';
export const CONFIGURATION_WORKER_ROLES = `${CONFIGURATION}/workerRoles`;
export const CONFIGURATION_SUB_CONTRACTORS = `${CONFIGURATION}/subContractors`;
export const MAPS_ZONES = '/maps-zones';
