import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { hotjar } from 'react-hotjar';
import Store from 'store';
import App from 'ui';
import * as serviceWorker from './serviceWorker';
import 'global.less';

process.env.REACT_APP_HOTJAR_ID &&
  // @ts-ignore
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV);

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
