import { combineReducers } from 'redux';
import installationState from 'store/state/installation';
import userManagementState from 'store/state/userManagement';
import { USER_LOGOUT_USER_SUCCESS, USMG__RESET, IN__RESET } from 'store/actions/actionTypes';

import userReducer from './user';
import clientsReducer from './clients';
import sitesReducer from './sites';
import sideModalReducer from './sideModal';
// import installationReducer from './installation';
import userManagemenrReducer from './userManagement';
import workerReducer from './workers';
import buildingReducer from './buildings';
import floorReducer from './floors';
import workshiftReducer from './workshifts';
import crewReducer from './crews';
import maintanceMapReducer from './maintanceMap';
import zoneReducer from './zones';
import anchorReducer from './anchors';
import workerRoles from './workerRoles';
import subContractors from './subContractors';

const appReducer = combineReducers({
  user: userReducer,
  clients: clientsReducer,
  sites: sitesReducer,
  buildings: buildingReducer,
  floors: floorReducer,
  // installation: installationReducer,
  maintanceMap: maintanceMapReducer,
  userManagement: userManagemenrReducer,
  sideModal: sideModalReducer,
  workers: workerReducer,
  workshifts: workshiftReducer,
  crews: crewReducer,
  zones: zoneReducer,
  anchors: anchorReducer,
  workerRoles,
  subcontractors: subContractors,
});

const rootReducer = (state: any, action: any): any => {
  switch (action.type) {
    case USER_LOGOUT_USER_SUCCESS:
      state = undefined;
      break;
    case USMG__RESET:
      state.userManagement = userManagementState;
      break;
    case IN__RESET:
      state.installation = installationState;
      break;
    default:
      break;
  }
  return appReducer(state, action);
};

export default rootReducer;
