import produce from 'immer';
import initialState, { Anchors, Anchor } from 'store/state/anchors';

import {
  LIST_APPROVED_ANCHORS_REQ,
  LIST_APPROVED_ANCHORS_SUCCESS,
  LIST_APPROVED_ANCHORS_FAILED,
  LIST_UNAPPROVED_ANCHORS_REQ,
  LIST_UNAPPROVED_ANCHORS_SUCCESS,
  LIST_UNAPPROVED_ANCHORS_FAILED,
  RESET_ANCHOR_SEARCH,
  APPROVE_ANCHOR_REQ,
  APPROVE_ANCHOR_SUCCESS,
  APPROVE_ANCHOR_FAILED,
  UNAPPROVE_ANCHOR_REQ,
  UNAPPROVE_ANCHOR_SUCCESS,
  UNAPPROVE_ANCHOR_FAILED,
  UPDATE_ANCHOR_LOCATION,
  ANCHOR_VIEW_ANCHOR,
  ANCHOR_SEARCH_ANCHORS,
  ANCHOR_CLEAR_ANCHORS,
  LIST_APPROVED_INVENTORY_REQ,
  LIST_APPROVED_INVENTORY_SUCCESS,
  LIST_APPROVED_INVENTORY_FAILED,
} from 'store/actions/actionTypes';

export default (state: Anchors = initialState, action: any): Anchors =>
  produce(state, (draft) => {
    // let updatedAnchor;
    switch (action.type) {
      //   case RESET:
      //     return initialState;
      case LIST_APPROVED_ANCHORS_REQ:
      case LIST_UNAPPROVED_ANCHORS_REQ:
      case APPROVE_ANCHOR_REQ:
      case UNAPPROVE_ANCHOR_REQ:
        draft.isLoading = true;
        break;
      case LIST_APPROVED_ANCHORS_SUCCESS:
        draft.isLoading = false;
        draft.searchType = '';
        draft.filterResApproved = [];
        draft.filterResApproved = [];
        draft.data.approved = action.payload;
        break;
      case LIST_UNAPPROVED_ANCHORS_SUCCESS:
        draft.isLoading = false;
        draft.searchType = '';
        draft.filterResApproved = [];
        draft.filterResApproved = [];
        draft.data.unapproved = action.payload;
        break;
      case ANCHOR_VIEW_ANCHOR:
        draft.selected = action.payload;
        break;
      case APPROVE_ANCHOR_SUCCESS: {
        const updatedAnchor: any = draft.data.unapproved.find(
          // eslint-disable-next-line
          (anchor: Anchor) => Number(anchor.id) === Number(action.payload.anchorId),
        );
        if (updatedAnchor) {
          updatedAnchor.coordinates = { coordinates: action.payload.coordinates };
          updatedAnchor.is_approved = true;
          draft.data.unapproved = draft.data.unapproved.filter(
            (anchor: Anchor) => Number(anchor.id) !== Number(action.payload.anchorId),
          );
          draft.data.approved.push(updatedAnchor);
        }
        draft.isLoading = false;
        draft.filterResApproved = [];
        draft.filterResUnapproved = [];
        draft.searchType = '';
        break;
      }
      case UNAPPROVE_ANCHOR_SUCCESS: {
        const updatedAnchor: any = draft.data.approved.find(
          // eslint-disable-next-line
          (anchor: Anchor) => Number(anchor.id) === Number(action.payload.anchorId),
        );
        if (updatedAnchor) {
          updatedAnchor.is_approved = false;
          updatedAnchor.coordinates = null;
          draft.data.approved = draft.data.approved.filter(
            (anchor: Anchor) => Number(anchor.id) !== Number(action.payload.anchorId),
          );
          draft.data.unapproved.push(updatedAnchor);
        }
        draft.isLoading = false;
        draft.filterResApproved = [];
        draft.filterResUnapproved = [];
        draft.searchType = '';
        break;
      }
      case UPDATE_ANCHOR_LOCATION: {
        const anchorIndex = draft.data.approved.findIndex(
          // eslint-disable-next-line
          (anchor: Anchor) => anchor.id === action.payload.anchorId,
        );
        // @ts-ignore
        draft.data.approved[anchorIndex].coordinates.coordinates = action.payload.coordinates;

        break;
      }
      case LIST_UNAPPROVED_ANCHORS_FAILED:
      case LIST_APPROVED_ANCHORS_FAILED:
      case APPROVE_ANCHOR_FAILED:
      case UNAPPROVE_ANCHOR_FAILED:
        draft.isLoading = false;
        break;
      case ANCHOR_SEARCH_ANCHORS:
        let refApprovedData = draft.data.approved;
        let refUnapprovedData = draft.data.unapproved;

        const genericFilter = (arr: Anchor[]): any[] =>
          arr.filter((item: any) => `${item.wnt_inventory_id}`.includes(action.payload));

        refApprovedData = genericFilter(refApprovedData);
        refUnapprovedData = genericFilter(refUnapprovedData);
        draft.searchType = action.payload;
        draft.filterResApproved = refApprovedData;
        draft.filterResUnapproved = refUnapprovedData;
        break;
      case RESET_ANCHOR_SEARCH:
        draft.searchType = '';
        draft.filterResApproved = draft.data.approved;
        draft.filterResUnapproved = draft.data.unapproved;
        break;
      case ANCHOR_CLEAR_ANCHORS:
        draft.inventory.approved = [];
        draft.data.approved = [];
        draft.data.unapproved = [];
        draft.selected = null;
        draft.searchType = '';
        draft.filterResUnapproved = [];
        draft.filterResApproved = [];
        break;
      case LIST_APPROVED_INVENTORY_REQ:
        draft.inventory.isLoading = true;
        draft.inventory.approved = [];
        break;
      case LIST_APPROVED_INVENTORY_SUCCESS:
        draft.inventory.approved = action.payload;
        draft.inventory.isLoading = false;
        break;
      case LIST_APPROVED_INVENTORY_FAILED:
        draft.inventory.isLoading = false;
        draft.inventory.approved = [];
        break;
      default:
        break;
    }
  });
