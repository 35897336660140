/* eslint-disable no-param-reassign */
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { notification } from 'antd';
import { getFromLocalStorage, clearLocalStorage } from 'utils/browser-storage';
import { NOT_AUTHORIZED, BAD_CREDENTIALS } from 'utils/httpCodes';
import { renderTemplateString } from 'utils/helpers';
// import { noInternetMessage } from 'utils/constants';
import { USER_CANCELLED } from 'utils/constants';

import urls from './urls';

export const { CancelToken } = Axios;

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_ROOT_URL,
});

const isNetworkError = (error: any): boolean => {
  return !error.response;
};

const wasLoginRequest = (request: XMLHttpRequest): boolean => {
  return request && request.responseURL === urls.user.login;
};

const redirectToLogout = (): void => {
  window.location.href = '/logout';
};
const redirectToLogin = (): void => {
  window.location.href = '/login';
};

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const { urlParams, url = '' } = config;
    if (urlParams) {
      config.url = renderTemplateString(url, urlParams);
    }
    if (url.startsWith(process.env.REACT_APP_ROOT_URL!)) {
      config = {
        ...config,
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${JSON.parse(getFromLocalStorage('auth') as string)}`,
        },
      };
    }
    return config;
  },
);

axiosInstance.interceptors.response.use(
  (config): AxiosResponse => config,
  (error): Promise<object> => {
    if (error.message === USER_CANCELLED) {
      // eslint-disable-next-line
      return Promise.reject({ message: USER_CANCELLED });
    }
    if (isNetworkError(error)) {
      // notification.destroy();
      // notification.error({ message: noInternetMessage(), duration: 1 });
      return Promise.reject();
    }
    const { status } = error.response;
    const token = getFromLocalStorage('auth');
    if (status === BAD_CREDENTIALS && !wasLoginRequest(error.request)) {
      if (token) {
        clearLocalStorage();
      }
      notification.error({
        message: 'Bad Credentials',
        onClose: redirectToLogin,
        duration: 1,
      });
      return Promise.reject();
    }
    if (status === NOT_AUTHORIZED) {
      notification.error({ message: 'Not Authorized', onClose: redirectToLogout, duration: 1 });
      return Promise.reject();
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
