import { produce } from 'immer';
import initialState, { WorkersStore } from 'store/state/workers';
import {
  TOOGLE_WORKERS_FETCH,
  TOGGLE_WORKERCREATE_MODAL,
  TOGGLE_WORKERVIEW_MODAL,
  VIEW_WORKER_SUCCESS,
  VIEW_WORKER_REQ,
  VIEW_WORKER_FAILED,
  ONLINE_WORKERS_REQ,
  ONLINE_WORKERS_SUCCESS,
  ONLINE_WORKERS_FAILED,
} from 'store/actions/actionTypes';

export default (state: WorkersStore = initialState, action: any): WorkersStore =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOOGLE_WORKERS_FETCH:
        draft.fetchWorkers = !draft.fetchWorkers;
        break;
      case TOGGLE_WORKERVIEW_MODAL:
        draft.isViewModal = action.payload;
        draft.isCreateModal = false;
        break;
      case TOGGLE_WORKERCREATE_MODAL:
        draft.isCreateModal = action.payload;
        draft.isViewModal = false;
        break;
      case VIEW_WORKER_REQ:
        draft.workerDetails.isLoading = true;
        break;
      case VIEW_WORKER_SUCCESS:
        draft.workerDetails.data = action.payload;
        draft.workerDetails.isLoading = false;
        break;
      case VIEW_WORKER_FAILED:
        draft.workerDetails.data = action.payload;
        draft.workerDetails.isLoading = false;
        break;
      case ONLINE_WORKERS_REQ:
        draft.onlineWorkers.data = [];
        draft.onlineWorkers.isLoading = true;
        break;
      case ONLINE_WORKERS_SUCCESS:
        draft.onlineWorkers.data = action.payload;
        const { activeCount, inActiveCount } = action.payload.reduce(
          (count: any, worker: any) => {
            if (worker.Inventory.is_active === true) {
              count.activeCount += 1;
            }
            if (worker.Inventory.is_active === false) {
              count.inActiveCount += 1;
            }
            return count;
          },
          { activeCount: 0, inActiveCount: 0 },
        );
        draft.onlineWorkers.activeCount = activeCount;
        draft.onlineWorkers.inActiveCount = inActiveCount;
        draft.onlineWorkers.isLoading = false;
        break;
      case ONLINE_WORKERS_FAILED:
        draft.onlineWorkers.isLoading = false;
        break;
      default:
        break;
    }
  });
