import axios from 'api';
import { AxiosResponse } from 'axios';
import urls from 'api/urls';
import { UserListItem } from 'store/state/userManagement';
import { Client } from 'store/state/clients';
import {
  CreateUserRequestBody,
  RegisterUserRequest,
  ResponseSuccess,
  EditUserRequestBody,
  ResetPasswordRequestBody,
} from './types';

export async function loginApi(email: string, password: string): Promise<any> {
  // console.log('=== : password', password);
  // console.log('=== : email', email);
  // return { data: { userName: 'ASDASD', id: 'asdfasdf', userEmail: 'asdfasdf' } };
  const response = await axios.post(urls.user.login, {
    email,
    password,
  });
  return response.data;
}

export async function logoutApi(): Promise<void> {
  const response = await axios.get(urls.user.logout);
  return response.data;
}

export async function createUser(formData: CreateUserRequestBody): Promise<UserListItem> {
  const response = await axios.post(urls.user.create, { ...formData });
  return response.data.data[0];
}

export async function forgotPassword(formData: { email: string }): Promise<any> {
  const response = await axios.post(urls.user.forgotPassword, { ...formData });
  return response.data;
}

export async function resetPassword(
  formData: ResetPasswordRequestBody,
  token: string,
): Promise<any> {
  const response = await axios.post(`${urls.user.resetPassword}/${token}`, { ...formData });
  return response.data;
}

export async function deactivateUser(userId: string): Promise<AxiosResponse> {
  const response = await axios.post(urls.user.deactivate(userId));
  return response.data;
}

export async function activateUser(userId: string): Promise<AxiosResponse> {
  const response = await axios.post(urls.user.activate(userId));
  return response.data;
}

export async function resendInvitation(userId: string): Promise<any> {
  const response = await axios.post(urls.user.resendInvitation(userId));
  return response.data;
}

export async function registerUser(
  signature: string,
  formData: RegisterUserRequest,
): Promise<ResponseSuccess> {
  const response = await axios.post(`${urls.user.register}/${signature}`, { ...formData });
  return response.data;
}

export async function fetchUserList(client_id?: string): Promise<AxiosResponse<UserListItem[]>> {
  const response = await axios.get(urls.user.list, {
    params: {
      client_id,
    },
  });
  return response.data;
}

export async function fetchUserClientList(userId: string): Promise<AxiosResponse<Client[]>> {
  const response = await axios.get(urls.user.clients(userId));
  return response.data;
}

export async function fetchUserSitesList(userId: string): Promise<AxiosResponse<Client[]>> {
  const response = await axios.get(urls.user.sites(userId));
  return response.data;
}

export async function edituser(
  userId: string,
  formData: EditUserRequestBody,
): Promise<AxiosResponse> {
  const response = await axios.post(urls.user.edit(userId), { ...formData });
  return response.data;
}

export async function userPreferences(locale: string): Promise<AxiosResponse> {
  const response = await axios.post(urls.user.userPreference, { locale });
  return response.data;
}

export async function getCurrentUserApi(): Promise<AxiosResponse<any>> {
  const response = await axios.get(urls.user.currentUser);
  return response.data;
}

export async function saveLastClientDetails(
  // @ts-ignore
  client_id: string | null,
  // @ts-ignore
  site_id: string | null,
): Promise<AxiosResponse<any>> {
  // const response = await axios.post(urls.user.recentlyVisited, {
  //   client_id,
  //   site_id,
  // });
  const response: any = await new Promise((res) => res({ data: {} }));
  return response.data;
}
