import produce from 'immer';
import { WorkShiftStore, initialState } from 'store/state/workshifts';
import { TOOGLE_WORKSHIFT_FETCH } from 'store/actions/actionTypes';

export default (state: WorkShiftStore = initialState, action: any): WorkShiftStore =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOOGLE_WORKSHIFT_FETCH:
        draft.fetchWorkshifts = !draft.fetchWorkshifts;
        break;
      default:
        break;
    }
  });
