import { produce } from 'immer';
import initialState, { Buildings } from 'store/state/buildings';
import {
  IN__VIEW_BUILDING,
  IN__LIST_BUILDINGS_REQ,
  IN__LIST_BUILDINGS_SUCCESS,
  IN__LIST_BUILDINGS_FAILED,
  IN__CREATE_BUILDING_SUCCESS,
  RESET_BUILDINGS,
} from 'store/actions/actionTypes';

const buildingReducer = (state = initialState, action: any): Buildings =>
  produce(state, (draft) => {
    switch (action.type) {
      case IN__LIST_BUILDINGS_REQ:
        draft.isLoading = true;
        draft.data = [];
        draft.selected = null;
        break;
      case IN__LIST_BUILDINGS_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        if (action.payload.length) {
          draft.selected = action.payload[0].id;
        }
        break;
      case IN__LIST_BUILDINGS_FAILED:
        draft.isLoading = false;
        break;
      case IN__CREATE_BUILDING_SUCCESS:
        // @ts-ignore
        draft.data.unshift(action.payload);
        break;
      case IN__VIEW_BUILDING:
        draft.selected = action.payload;
        break;
      case RESET_BUILDINGS:
        draft.selected = initialState.selected;
        draft.isLoading = initialState.isLoading;
        draft.data = initialState.data;
        break;
      default:
        break;
    }
  });

export default buildingReducer;
