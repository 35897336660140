import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from 'ui/router';
import LoadingPage from 'ui/components/Loading';

import './styles/index.less';

function Layout(): JSX.Element {
  return (
    <Suspense fallback={<LoadingPage />}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Suspense>
  );
}

export default Layout;
