const baseUrl = `${process.env.REACT_APP_ROOT_URL}/api`;

export default {
  user: {
    login: `${baseUrl}/login`,
    logout: `${baseUrl}/logout`,
    create: `${baseUrl}/invite`,
    resetPassword: `${baseUrl}/reset-password`,
    register: `${baseUrl}/register`,
    forgotPassword: `${baseUrl}/forgot-password`,
    list: `${baseUrl}/users`,
    clients: (userId: string): string => `${baseUrl}/users/${userId}/clients`,
    sites: (userId: string): string => `${baseUrl}/users/${userId}/sites`,
    deactivate: (userId: string): string => `${baseUrl}/users/${userId}/deactivate`,
    activate: (userId: string): string => `${baseUrl}/users/${userId}/activate`,
    edit: (userId: string): string => `${baseUrl}/users/${userId}`,
    resendInvitation: (userId: string): string => `${baseUrl}/users/${userId}/reinvite`,
    userPreference: `${baseUrl}/users/preferences`,
    currentUser: `${baseUrl}/user`,
    recentlyVisited: `${baseUrl}/users/recently-visited`,
  },
  clients: {
    list: `${baseUrl}/clients`,
    create: `${baseUrl}/clients`,
  },
  sites: {
    list: (clientId: string): string => `${baseUrl}/clients/${clientId}/sites`,
    create: `${baseUrl}/sites`,
    listNetworks: `${baseUrl}/networks/unassigned`,
    assignNetwork: (networkId: string): string => `${baseUrl}/networks/${networkId}`,
    createSubContractor: (siteId: string): string => `${baseUrl}/sites/${siteId}/subcontractors`,
    rolesList: (siteId: string): string => `${baseUrl}/sites/${siteId}/worker-roles`,
    contractorsList: (siteId: string): string => `${baseUrl}/sites/${siteId}/subcontractors`,
    listPowerBI: (siteId: string): string => `${baseUrl}/sites/${siteId}/power-bi`,
    inventoryMeta: (siteId: string): string => `${baseUrl}/sites/${siteId}/inventory-meta`,
    editWorkerRole: (siteId: string, roleId: string): string =>
      `${baseUrl}/sites/${siteId}/worker-roles/${roleId}`,
  },
  buildings: {
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/buildings`,
    create: (siteId: string): string => `${baseUrl}/sites/${siteId}/buildings`,
    inventoryMeta: (buildingId: string): string =>
      `${baseUrl}/buildings/${buildingId}/inventory-meta`,
  },
  floors: {
    create: (buildingId: string): string => `${baseUrl}/buildings/${buildingId}/floors`,
    edit: (buildingId: string, floorId: string): string =>
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}`,
    list: (buildingId: string): string => `${baseUrl}/buildings/${buildingId}/floors`,
    view: (buildingId: string, floorId: string): string =>
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}`,
    delete: (buildingId: string, floorId: string): string =>
      `${baseUrl}/buildings/${buildingId}/floors/${floorId}`,
    inventoryMeta: (floorId: string): string => `${baseUrl}/floors/${floorId}/inventory-meta`,
    inventoryStatistics: (floorId: string): string =>
      `${baseUrl}/floors/${floorId}/inventory-statistics`,
    anchorStatistics: (floorId: string): string => `${baseUrl}/floors/${floorId}/anchor-statistics`,
  },
  zones: {
    list: (floorId: string): string => `${baseUrl}/floors/${floorId}/zones`,
    create: (floorId: string): string => `${baseUrl}/floors/${floorId}/zones`,
    edit: (floorId: string, zoneId: string): string =>
      `${baseUrl}/floors/${floorId}/zones/${zoneId}`,
    delete: (floorId: string, zoneId: string): string =>
      `${baseUrl}/floors/${floorId}/zones/${zoneId}`,
  },
  anchors: {
    listApproved: (floorId: string): string => `${baseUrl}/floors/${floorId}/inventory`,
    listUnApproved: (networkId: string): string => `${baseUrl}/networks/${networkId}/inventory`,
    updateAnchor: (floorId: string, anchorId: string): string =>
      `${baseUrl}/floors/${floorId}/inventory/${anchorId}`,
    nodeDiagnostics: (floorId: string): string => `${baseUrl}/floors/${floorId}/node-information`,
  },
  workers: {
    createWorkerRole: (siteId: string): string => `${baseUrl}/sites/${siteId}/worker-roles`,
    createWorker: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers`,
    updateWorker: (siteId: string, workerId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}`,
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers`,
    view: (siteId: string, workerId: number): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}`,
    delete: (siteId: string, workerId: number): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}`,
    statistics: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers/statistics`,
    unAssignHelmet: (siteId: string, workerId: number): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}/inventories/unassign`,
    assignHelmet: (siteId: string, workerId: number, asset_id: number): string =>
      `${baseUrl}/sites/${siteId}/workers/${workerId}/inventories/assign/${asset_id}`,
    assignWorkers: (siteId: string, crewId: string): string =>
      `${baseUrl}/sites/${siteId}/workers/crews/${crewId}/assign`,
    template: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers/download/template`,
    uploadExcel: (siteId: string): string => `${baseUrl}/sites/${siteId}/workers/upload`,
  },
  assets: {
    listUnApproved: (networkId: string): string =>
      `${baseUrl}/networks/${networkId}/inventory/assets`,
  },
  workshifts: {
    createWorkshift: (siteId: string): string => `${baseUrl}/sites/${siteId}/workshifts`,
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/workshifts`,
  },
  crews: {
    createCrew: (siteId: string): string => `${baseUrl}/sites/${siteId}/crews`,
    list: (siteId: string): string => `${baseUrl}/sites/${siteId}/crews`,
  },
};
